/*=====================================
=           Seminar booking           =
=====================================*/
.seminar-booking-header {
  margin-bottom: 15px;
}
.seminar-booking-title,
.seminar-booking-subtitle {
  margin-bottom: 0;
}
.seminar-booking-subtitle {
  margin-top: 5px;
}
